import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Markup } from "interweave";
import { Link, useNavigate } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import FormFind from "../global/FormFind";
import { FormatDate } from "../global/FormatDate";
import Aos from "aos";
import "aos/dist/aos.css";
import HeartDefault from "../assets/img/heart_default.svg";
import HeartActive from "../assets/img/heart_active.svg";
import ADD_WISHLIST from "../graphql/add-wishlist";
import REMOVE_WISHLIST from "../graphql/remove-wishlist";
import {
  getInforUser,
  getStatusLogin,
  NumberFormatter,
} from "../ulti/function";
import ToastCustom from "../global/ToastCustom";
import AddressIcon from "../assets/img/address.svg";
import TrafficIcon from "../assets/img/traffic.svg";
import MoneyIcon from "../assets/img/money.svg";
import SENDMAIL from "../graphql/send-mail";
import PAGES from "../graphql/pages";
import DefaultImgBlog from "../assets/img/default_blog.png";
import DefaultImgHouse from "../assets/img/e2bf3f6943481d3ecd5ae3e7061641b6.png";

const Homepage = ({ uri, dataGlobal }) => {
  useEffect(() => {
    Aos.init();
  }, []);
  const [addWishlist, { loading: wishlistLoading, error: wishlistError }] =
    useMutation(ADD_WISHLIST, {
      onCompleted: async (data) => {
        try {
          // await refetch();
          // setToastMessage("Success!!!");
          // setToastStatus("success");
        } catch (refetchError) {
          console.log("Error during refetch:", refetchError);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });
  const [toastMessage, setToastMessage] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [dataHomepage, setDataHomepage] = useState();
  const [dataListWishlist, setDataListWishlist] = useState("");
  const [redirectLink, setRedirectLink] = useState("");
  const statusLogin = getStatusLogin();
  const navigate = useNavigate();
  const [
    removeWishlist,
    { loading: removeWishlistLoading, error: removeWishlistError },
  ] = useMutation(REMOVE_WISHLIST, {
    onCompleted: async (data) => {
      // await refetch();
      // setToastMessage("Success!!!");
      // setToastStatus("success");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const [sendMail, { loading: sendLoad, error: sendError }] = useMutation(
    SENDMAIL,
    {
      onCompleted: async (data) => {
        setToastMessage("Success!!!");
        setToastStatus("success");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    say: "",
  });
  const inforUser = getInforUser();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleWishlist = async (id) => {
    if (!statusLogin) {
      navigate("/login");
    } else {
      try {
        await addWishlist({
          variables: {
            input: {
              userId: inforUser.userId,
              houseId: parseInt(id),
            },
          },
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const handleRemoveWishlist = async (id) => {
    if (!statusLogin) {
      navigate("/login");
    } else {
      try {
        await removeWishlist({
          variables: {
            input: {
              userId: inforUser.userId,
              houseId: parseInt(id),
            },
          },
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const handleSubmitMail = async (event) => {
    event.preventDefault();
    if (!statusLogin) {
      navigate("/login");
    } else {
      const emailData = {
        to: dataGlobal
          ? dataGlobal.messageFormACF.contentMessageForm.messageForm.to
          : "gianguno10508@gmail.com",
        subject: dataGlobal
          ? dataGlobal.messageFormACF.contentMessageForm.messageForm.subject
          : "gianguno10508@gmail.com",
        message: `Fullname: ${formData.name}<br>Email: ${formData.email}<br>Phone: ${formData.phone}<br>Message: ${formData.say}`,
      };
      try {
        await sendMail({
          variables: {
            input: {
              message: emailData.message,
              subject: emailData.subject,
              to: emailData.to,
            },
          },
        });
      } catch (error) {
        console.error("Error send mail:", error);
      }
    }
  };
  const { loading, error, refetch } = useQuery(PAGES, {
    variables: { input: inforUser ? inforUser.userId : 0, idPage: uri },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      // setMessageForm();
      setDataHomepage(data.page.homepage.contentPage);
      setDataListWishlist(data.wishlistHouses);
    },
    onError: (error) => {
      console.log("Query error:", error);
    },
  });

  const [processingId, setProcessingId] = useState(null);

  const handleWishlistClick = (id, isWishlisted) => {
    setProcessingId(id);
    const buttonElement = document.querySelector(`[data-id="${id}"]`);
    if (buttonElement.classList.contains("wishlist-icon")) {
      buttonElement.classList.remove("wishlist-icon");
    } else {
      buttonElement.classList.add("wishlist-icon");
    }
    if (isWishlisted) {
      handleRemoveWishlist(id).finally(() => setProcessingId(null));
    } else {
      handleWishlist(id).finally(() => setProcessingId(null));
    }
  };
  // useEffect(() => {
  //   refetch()
  //     .then((result) => {
  //       console.log(result);

  //       setDataHomepage(result.data.page.homepage.contentPage);
  //       setDataListWishlist(result.data.wishlistHouses);
  //     })
  //     .catch((error) => {
  //       console.log("Error during refetch:", error);
  //     });
  // }, [refetch, activeLanguage]);
  if (loading) {
    window.scrollTo(0, 0);
    return (
      <div className="load">
        <span className="loader"></span>
      </div>
    );
  }
  if (error) return <p>Error : {error.message}</p>;
  if (dataHomepage) {
    return (
      <div className="homepage">
        <div>
          {toastMessage !== "" && toastStatus !== "" && (
            <ToastCustom
              message={toastMessage}
              status={toastStatus}
              redirect={redirectLink}
              speed={redirectLink !== "" ? 500 : 2000}
            />
          )}
          <div className="container wrap-page">
            <div
              className="section hero-banner"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
              data-aos-duration="300"
            >
              <Swiper
                spaceBetween={30}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                navigation={true}
                loop={true}
                lazy={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Navigation, Pagination]}
                className="mySwiper-banner"
              >
                {dataHomepage.sectionBanner.imageBannerSlider &&
                  dataHomepage.sectionBanner.imageBannerSlider.map(
                    (item, index) =>
                      item.imageSlider && (
                        <SwiperSlide key={index}>
                          <img
                            loading="lazy"
                            src={item.imageSlider.node.mediaItemUrl}
                            alt={`img ${index}`}
                          />
                        </SwiperSlide>
                      )
                  )}
              </Swiper>
            </div>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
              data-aos-duration="300"
            >
              <FormFind dataGlobal={dataGlobal} checkHome={true} />
            </div>
            <div
              className="section st-about"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
              data-aos-duration="300"
            >
              <div className="wrap-box">
                <div className="col image">
                  <img
                    src={dataHomepage.sectionAboutUs.image.node.mediaItemUrl}
                    loading="lazy"
                    alt="Logo"
                    className="logo-footer"
                    width="626"
                    height="418"
                  />
                </div>
                <div className="col txt">
                  <div className="line blue"></div>
                  <h2 className="heading">
                    {dataHomepage.sectionAboutUs.title}
                  </h2>
                  <Markup content={dataHomepage.sectionAboutUs.description} />
                  <Link
                    to={dataHomepage.sectionAboutUs.button.url}
                    className="btn-see-more"
                    target={dataHomepage.sectionAboutUs.button.target}
                  >
                    <span className="see-more">
                      {dataHomepage.sectionAboutUs.button.title}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
              data-aos-duration="300"
              className="section st-apartment"
              style={{
                backgroundImage: `url(${dataHomepage.sectionApartment.background.node.mediaItemUrl})`,
              }}
            >
              <div className="wrap-box">
                <div className="line white"></div>
                <h2 className="sm-heading txt-white">
                  {dataHomepage.sectionApartment.title}
                </h2>
                <p className="desc">
                  {dataHomepage.sectionApartment.description}
                </p>
                <div className="list-apart">
                  <div className="swiper-button-next arrow-btn apart-next"></div>
                  <div className="swiper-button-prev arrow-btn apart-prev"></div>
                  <Swiper
                    slidesPerView={4}
                    spaceBetween={32}
                    loop={false}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={{
                      nextEl: ".apart-next",
                      prevEl: ".apart-prev",
                    }}
                    breakpoints={{
                      320: { slidesPerView: 1.3, spaceBetween: 12 },
                      580: { slidesPerView: 2.2, spaceBetween: 12 },
                      768: { slidesPerView: 2.5, spaceBetween: 24 },
                      1025: { slidesPerView: 3 },
                      1280: { slidesPerView: 4 },
                    }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {dataHomepage.sectionApartment.apartments &&
                      dataHomepage.sectionApartment.apartments.nodes.map(
                        (item, index) => {
                          const isWishlisted = dataListWishlist.some(
                            (wishlist) =>
                              parseInt(wishlist.id) === item.housesId
                          );
                          return (
                            <SwiperSlide key={index}>
                              <div className="item-apatto">
                                <div className="wrap-item">
                                  <div className="img-featured">
                                    <img
                                      src={
                                        item.featuredImage
                                          ? item.featuredImage.node.mediaItemUrl
                                          : DefaultImgHouse
                                      }
                                      alt="Logo"
                                      loading="lazy"
                                      className="logo-footer"
                                      width="626"
                                      height="418"
                                    />
                                    <button
                                      data-id={item.housesId}
                                      key={item.housesId}
                                      onClick={() =>
                                        handleWishlistClick(
                                          item.housesId,
                                          isWishlisted
                                        )
                                      }
                                      className={`btn-wishlist ${
                                        isWishlisted ? "wishlist-icon" : ""
                                      }`}
                                    >
                                      {processingId === item.housesId &&
                                      (removeWishlistLoading ||
                                        wishlistLoading) ? (
                                        <span className="loader load-ajax"></span>
                                      ) : (
                                        <>
                                          <img
                                            style={{ width: "26px" }}
                                            className="heart heart-default"
                                            src={HeartDefault}
                                            loading="lazy"
                                            alt="heart default"
                                          />
                                          <img
                                            style={{ width: "26px" }}
                                            className="heart heart-active"
                                            src={HeartActive}
                                            loading="lazy"
                                            alt="heart active"
                                          />
                                        </>
                                      )}
                                    </button>
                                  </div>
                                  <h3 className="title">
                                    <Link to={`/house/${item.housesAcf.houseCode}`}>
                                      {item.housesAcf &&
                                        item.housesAcf.houseCode}
                                    </Link>
                                  </h3>
                                  <div className="address">
                                    <img
                                      loading="lazy"
                                      src={AddressIcon}
                                      style={{ maxWidth: "16px" }}
                                      alt="addressicon"
                                    />
                                    <span className="text">
                                      {item.housesAcf && item.housesAcf.address}
                                    </span>
                                  </div>
                                  <div className="eki">
                                    <img
                                      loading="lazy"
                                      src={TrafficIcon}
                                      style={{ maxWidth: "16px" }}
                                      alt="trafficicon"
                                    />
                                    <span className="text">
                                      {item.housesAcf.traffic.trafficContent &&
                                        item.housesAcf.traffic.trafficContent[0]
                                          .data}
                                    </span>
                                  </div>
                                  <div className="kakaku-tai">
                                    <img
                                      loading="lazy"
                                      src={MoneyIcon}
                                      style={{ maxWidth: "16px" }}
                                      alt="moneyicon"
                                    />
                                    <div className="box-price">
                                      <span className="price">
                                        {item.housesAcf.price && (
                                          <NumberFormatter
                                            numberString={item.housesAcf.price}
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="show-more">
                                    <Link to={`/house/${item.housesAcf.houseCode}`}>
                                      <span className="btn">Show More</span>
                                      <span className="icon-view">
                                        <svg
                                          width="13"
                                          height="22"
                                          viewBox="0 0 13 22"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M0.350515 1.89321L10.1225 10.999L0.349338 20.1047C-0.116446 20.5388 -0.116446 21.2411 0.349338 21.6752C0.815122 22.1083 1.56977 22.1083 2.03555 21.6752L12.6507 11.7847C13.1164 11.3517 13.1164 10.6483 12.6507 10.2153L2.03565 0.32478C1.56987 -0.108257 0.814044 -0.108257 0.34826 0.32478C-0.115269 0.75672 -0.115269 1.46018 0.350515 1.89321Z"
                                            fill="url(#paint0_linear_68_2837)"
                                          />
                                          <defs>
                                            <linearGradient
                                              id="paint0_linear_68_2837"
                                              x1="-6.03805e-07"
                                              y1="2.51044e-07"
                                              x2="6.65378"
                                              y2="22.9569"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stopColor="#0193FD" />
                                              <stop
                                                offset="1"
                                                stopColor="#133DD3"
                                              />
                                            </linearGradient>
                                          </defs>
                                        </svg>
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        }
                      )}
                  </Swiper>
                </div>
              </div>
            </div>
            <div
              className="section st-contact"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
              data-aos-duration="300"
              id="st-contact"
            >
              <div
                className="bgr-form bgr-avatar"
                style={{
                  backgroundImage: `url(${dataHomepage.sectionContact.backgroundSection.node.mediaItemUrl})`,
                }}
              >
                <div className="wrap-form bgr-icon">
                  <h2 className="sm-heading txt-black">
                    <Markup content={dataHomepage.sectionContact.title} />
                  </h2>
                  <form onSubmit={handleSubmitMail}>
                    <div className="form-input">
                      <input
                        type="text"
                        name="name"
                        placeholder={
                          dataHomepage.sectionContact.textInput[0].value
                        }
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-input">
                      <input
                        type="email"
                        name="email"
                        placeholder={
                          dataHomepage.sectionContact.textInput[1].value
                        }
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-input">
                      <input
                        type="text"
                        name="phone"
                        placeholder={
                          dataHomepage.sectionContact.textInput[2].value
                        }
                        required
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-input">
                      <input
                        type="text"
                        name="say"
                        placeholder={
                          dataHomepage.sectionContact.textInput[3].value
                        }
                        value={formData.say}
                        onChange={handleChange}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn-submit"
                      disabled={sendLoad}
                    >
                      <span>{dataHomepage.sectionContact.textButton}</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div
              className="section st-blog home-blog-bgr"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
              data-aos-duration="300"
            >
              <div className="container wrap-blog">
                <div className="box-top">
                  <div className="line blue"></div>
                  <h2 className="sm-heading txt-black">
                    {dataHomepage.sectionBlog.title}
                  </h2>
                  <p className="desc">{dataHomepage.sectionBlog.description}</p>
                  <Link
                    className="btn_hover-underline see-more"
                    to={dataHomepage.sectionBlog.button.url}
                    target={dataHomepage.sectionBlog.button.target}
                  >
                    {dataHomepage.sectionBlog.button.title}
                  </Link>
                </div>
                {dataHomepage.sectionBlog.selectPosts && (
                  <div className="list-blog">
                    <div className="col-blog feat-blog item-blog">
                      <div className="box-img">
                        <Link
                          to={
                            dataHomepage.sectionBlog.selectPosts.nodes.length >
                              0 &&
                            "/blog/" +
                              dataHomepage.sectionBlog.selectPosts.nodes[0].slug
                          }
                        >
                          <img
                            src={
                              dataHomepage.sectionBlog.selectPosts.nodes
                                .length > 0 &&
                              dataHomepage.sectionBlog.selectPosts.nodes[0]
                                .featuredImage
                                ? dataHomepage.sectionBlog.selectPosts.nodes[0]
                                    .featuredImage.node.mediaItemUrl
                                : DefaultImgBlog
                            }
                            loading="lazy"
                            alt="IMG"
                            className=""
                            width="1120"
                            height="747"
                          />
                        </Link>
                      </div>
                      <div className="info-blog">
                        <h3 className="title-post">
                          <Link
                            className="post-link"
                            to={
                              dataHomepage.sectionBlog.selectPosts.nodes
                                .length > 0 &&
                              "/blog/" +
                                dataHomepage.sectionBlog.selectPosts.nodes[0]
                                  .slug
                            }
                          >
                            {dataHomepage.sectionBlog.selectPosts.nodes.length >
                              0 &&
                              dataHomepage.sectionBlog.selectPosts.nodes[0]
                                .title}
                          </Link>
                        </h3>
                        <div className="aut-date">
                          <div className="in-line datetime">
                            <svg
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.99331 1.41797C4.31331 1.41797 1.33331 4.40464 1.33331 8.08463C1.33331 11.7646 4.31331 14.7513 7.99331 14.7513C11.68 14.7513 14.6666 11.7646 14.6666 8.08463C14.6666 4.40464 11.68 1.41797 7.99331 1.41797ZM10.6666 10.7513C10.605 10.8131 10.5317 10.8621 10.4511 10.8956C10.3704 10.929 10.284 10.9463 10.1966 10.9463C10.1093 10.9463 10.0229 10.929 9.94223 10.8956C9.86158 10.8621 9.78832 10.8131 9.72665 10.7513L7.53331 8.55797C7.47032 8.49623 7.42021 8.4226 7.38588 8.34134C7.35154 8.26009 7.33368 8.17284 7.33331 8.08463V5.41797C7.33331 5.0513 7.63331 4.7513 7.99998 4.7513C8.36665 4.7513 8.66665 5.0513 8.66665 5.41797V7.8113L10.6666 9.8113C10.9266 10.0713 10.9266 10.4913 10.6666 10.7513Z"
                                fill="#888888"
                              />
                            </svg>
                            <span className="time-read">
                              <span className="tm">8</span> min read
                            </span>
                            |
                            <span className="publ-date">
                              {dataHomepage.sectionBlog.selectPosts.nodes
                                .length > 0 && (
                                <FormatDate
                                  dateString={
                                    dataHomepage.sectionBlog.selectPosts
                                      .nodes[0].dateGmt
                                  }
                                />
                              )}
                            </span>
                          </div>
                          <div className="in-line author">
                            <img
                              src={
                                dataHomepage.sectionBlog.selectPosts.nodes
                                  .length > 0 &&
                                dataHomepage.sectionBlog.selectPosts.nodes[0]
                                  .author.node.avatar.url
                              }
                              alt="IMG"
                              loading="lazy"
                              className=""
                              width="24"
                              height="24"
                            />
                            <span className="name">
                              {dataHomepage.sectionBlog.selectPosts.nodes
                                .length > 0 &&
                                dataHomepage.sectionBlog.selectPosts.nodes[0]
                                  .author.node.name}
                            </span>
                          </div>
                        </div>
                        <div className="address">
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_800_3369)">
                              <path
                                d="M11.2619 11.7832L10.6453 12.9427L13.6645 12.4491C13.0552 12.1435 12.2304 11.9251 11.2619 11.7832ZM3.48433 12.036C2.08593 12.4048 1.20001 13.0091 1.20001 13.9067C1.20059 14.1 1.24283 14.2908 1.32385 14.4662L6.16945 13.6742L3.48433 12.036ZM14.6864 13.3832L7.94241 14.4824L9.72097 16.5058C12.6416 16.2442 14.8 15.3112 14.8 13.9067C14.8 13.7195 14.7597 13.5454 14.6864 13.3832ZM6.80289 14.672L2.24609 15.4168C3.45057 16.163 5.57649 16.5832 8.00001 16.5832C8.14689 16.5832 8.29041 16.579 8.43505 16.576L6.80289 14.672Z"
                                fill="url(#paint0_linear_800_3369)"
                              />
                              <path
                                d="M8.00034 0.583984C5.39234 0.583984 3.2525 2.71726 3.2525 5.31934C3.2525 6.31966 3.57042 7.2515 4.10722 8.01774L7.18466 13.3377L7.19778 13.3549C7.31922 13.5134 7.43778 13.6385 7.57698 13.7305C7.71618 13.8227 7.88898 13.8777 8.05858 13.8608C8.39746 13.8269 8.60498 13.5875 8.80194 13.3206L8.8125 13.3069L12.2099 7.5243L12.2119 7.52063C12.2915 7.37663 12.3504 7.23006 12.4003 7.08734C12.6295 6.52607 12.7474 5.92561 12.7475 5.31934C12.7475 2.71726 10.6083 0.583984 8.00034 0.583984ZM8.00034 3.31118C9.1317 3.31118 10.0147 4.19262 10.0147 5.31918C10.0147 6.44606 9.1317 7.32719 8.00034 7.32719C6.86882 7.32719 5.9853 6.4459 5.9853 5.31918C5.9853 4.19278 6.86898 3.31118 8.00034 3.31118Z"
                                fill="url(#paint1_linear_800_3369)"
                              />
                            </g>
                            <defs>
                              <linearGradient
                                id="paint0_linear_800_3369"
                                x1="1.20001"
                                y1="14.1832"
                                x2="5.42678"
                                y2="20.4775"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#1541DF" />
                                <stop offset="1" stopColor="#0E30A7" />
                              </linearGradient>
                              <linearGradient
                                id="paint1_linear_800_3369"
                                x1="3.2525"
                                y1="7.22391"
                                x2="11.5726"
                                y2="10.3505"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#1541DF" />
                                <stop offset="1" stopColor="#0E30A7" />
                              </linearGradient>
                              <clipPath id="clip0_800_3369">
                                <rect
                                  width="16"
                                  height="16"
                                  fill="white"
                                  transform="translate(0 0.583984)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span className="label">Địa chỉ công ty:</span>
                          <span className="ad">
                            {dataHomepage.sectionBlog.selectPosts.nodes.length >
                              0 &&
                              dataHomepage.sectionBlog.selectPosts.nodes[0]
                                .postsACF.address}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-blog lt-blog">
                      {dataHomepage.sectionBlog.selectPosts.nodes.length > 0 &&
                        dataHomepage.sectionBlog.selectPosts.nodes
                          .slice(1)
                          .map((item, index) => (
                            <div
                              className="wrap-item-blog item-blog"
                              key={index}
                            >
                              <div className="box-img">
                                <Link to={"/blog/" + item.slug}>
                                  <img
                                    src={
                                      item.featuredImage
                                        ? item.featuredImage.node.mediaItemUrl
                                        : DefaultImgBlog
                                    }
                                    alt="IMG"
                                    className=""
                                    loading="lazy"
                                    width="1120"
                                    height="747"
                                  />
                                </Link>
                              </div>
                              <div className="info-blog">
                                <h3 className="title-post">
                                  <Link
                                    to={"/blog/" + item.slug}
                                    className="post-link"
                                  >
                                    {item.title}
                                  </Link>
                                </h3>
                                <div className="aut-date">
                                  <div className="in-line datetime">
                                    <svg
                                      width="16"
                                      height="17"
                                      viewBox="0 0 16 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.99331 1.41797C4.31331 1.41797 1.33331 4.40464 1.33331 8.08463C1.33331 11.7646 4.31331 14.7513 7.99331 14.7513C11.68 14.7513 14.6666 11.7646 14.6666 8.08463C14.6666 4.40464 11.68 1.41797 7.99331 1.41797ZM10.6666 10.7513C10.605 10.8131 10.5317 10.8621 10.4511 10.8956C10.3704 10.929 10.284 10.9463 10.1966 10.9463C10.1093 10.9463 10.0229 10.929 9.94223 10.8956C9.86158 10.8621 9.78832 10.8131 9.72665 10.7513L7.53331 8.55797C7.47032 8.49623 7.42021 8.4226 7.38588 8.34134C7.35154 8.26009 7.33368 8.17284 7.33331 8.08463V5.41797C7.33331 5.0513 7.63331 4.7513 7.99998 4.7513C8.36665 4.7513 8.66665 5.0513 8.66665 5.41797V7.8113L10.6666 9.8113C10.9266 10.0713 10.9266 10.4913 10.6666 10.7513Z"
                                        fill="#888888"
                                      />
                                    </svg>
                                    <span className="time-read">
                                      <span className="tm">8</span> min read
                                    </span>
                                    |
                                    <span className="publ-date">
                                      <FormatDate dateString={item.dateGmt} />
                                    </span>
                                  </div>
                                  <div className="in-line author">
                                    <img
                                      src={item.author.node.avatar.url}
                                      loading="lazy"
                                      alt="IMG"
                                      className=""
                                      width="24"
                                      height="24"
                                    />
                                    <span className="name">
                                      {item.author.node.name}
                                    </span>
                                  </div>
                                </div>
                                <div className="address">
                                  <svg
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_800_3369)">
                                      <path
                                        d="M11.2619 11.7832L10.6453 12.9427L13.6645 12.4491C13.0552 12.1435 12.2304 11.9251 11.2619 11.7832ZM3.48433 12.036C2.08593 12.4048 1.20001 13.0091 1.20001 13.9067C1.20059 14.1 1.24283 14.2908 1.32385 14.4662L6.16945 13.6742L3.48433 12.036ZM14.6864 13.3832L7.94241 14.4824L9.72097 16.5058C12.6416 16.2442 14.8 15.3112 14.8 13.9067C14.8 13.7195 14.7597 13.5454 14.6864 13.3832ZM6.80289 14.672L2.24609 15.4168C3.45057 16.163 5.57649 16.5832 8.00001 16.5832C8.14689 16.5832 8.29041 16.579 8.43505 16.576L6.80289 14.672Z"
                                        fill="url(#paint0_linear_800_3369)"
                                      />
                                      <path
                                        d="M8.00034 0.583984C5.39234 0.583984 3.2525 2.71726 3.2525 5.31934C3.2525 6.31966 3.57042 7.2515 4.10722 8.01774L7.18466 13.3377L7.19778 13.3549C7.31922 13.5134 7.43778 13.6385 7.57698 13.7305C7.71618 13.8227 7.88898 13.8777 8.05858 13.8608C8.39746 13.8269 8.60498 13.5875 8.80194 13.3206L8.8125 13.3069L12.2099 7.5243L12.2119 7.52063C12.2915 7.37663 12.3504 7.23006 12.4003 7.08734C12.6295 6.52607 12.7474 5.92561 12.7475 5.31934C12.7475 2.71726 10.6083 0.583984 8.00034 0.583984ZM8.00034 3.31118C9.1317 3.31118 10.0147 4.19262 10.0147 5.31918C10.0147 6.44606 9.1317 7.32719 8.00034 7.32719C6.86882 7.32719 5.9853 6.4459 5.9853 5.31918C5.9853 4.19278 6.86898 3.31118 8.00034 3.31118Z"
                                        fill="url(#paint1_linear_800_3369)"
                                      />
                                    </g>
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_800_3369"
                                        x1="1.20001"
                                        y1="14.1832"
                                        x2="5.42678"
                                        y2="20.4775"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#1541DF" />
                                        <stop offset="1" stopColor="#0E30A7" />
                                      </linearGradient>
                                      <linearGradient
                                        id="paint1_linear_800_3369"
                                        x1="3.2525"
                                        y1="7.22391"
                                        x2="11.5726"
                                        y2="10.3505"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#1541DF" />
                                        <stop offset="1" stopColor="#0E30A7" />
                                      </linearGradient>
                                      <clipPath id="clip0_800_3369">
                                        <rect
                                          width="16"
                                          height="16"
                                          fill="white"
                                          transform="translate(0 0.583984)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <span className="label">
                                    Địa chỉ công ty:
                                  </span>
                                  <span className="ad">
                                    {item.postsACF.address}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default Homepage;
