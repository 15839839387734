import { useEffect, useState } from "react";
import Aos from "aos";
import { useQuery } from "@apollo/client";
import GET_DETAIL_BLOG from "../graphql/detail-blog";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getActiveLanguageLocal, getStatusLogin } from "../ulti/function";
import AvtDefault from "../assets/img/avt_default.png";
import ArrowBack from "../assets/img/eva_arrow-back-fill.svg";
import Clock from "../assets/img/ic_round-access-time.svg";
import RoundAvt from "../assets/img/round_avt.png";
import HouseDetail from "../assets/img/house_detail.png";
import RoundMiddle from "../assets/img/round_middle.png";
import RoundBottom from "../assets/img/round_bottom.png";
import { Markup } from "interweave";
import GET_RELATED_POSTS from "../graphql/related-posts";
import { GetDay, GetMonth } from "../global/FormatDate";
import DefaultBlog from "../assets/img/default_blog.png";
import HouseBottomLeft from "../assets/img/house_bottom_left.png";
import HouseBottomRight from "../assets/img/house_bottom_right.png";

const DetailBlog = () => {
  const [dataPage, setDataPage] = useState("");
  const [idResponse, setIdResponse] = useState("");
  const id_blog = useParams();
  useEffect(() => {
    Aos.init();
  }, []);
  const statusLogin = getStatusLogin();
  const navigate = useNavigate();
  useEffect(() => {
    if (!statusLogin) {
      navigate("/login");
    }
  }, []);
  const handleBack = () => {
    navigate(-1);
  };
  const language = getActiveLanguageLocal();
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? " PM" : " AM";
    hours = hours % 12 || 12;
    return `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  };
  const { loading, error, refetch } = useQuery(GET_DETAIL_BLOG, {
    variables: {
      idBlog: id_blog.slug,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const translation = data.postBy.translations.find(
        (t) => t.language.code === language.toUpperCase()
      );
      const postContent = translation ? translation : data.postBy;
      setDataPage(postContent);
      setIdResponse(postContent.id)
    },
    onError: (error) => {
      console.log("Query error:", error);
    },
  });
  const categoryId = dataPage?.categories?.nodes.map((category) => category.id);
  const {
    loading: loadingRelated,
    error: errorRelated,
    data: dataRelated,
  } = useQuery(GET_RELATED_POSTS, {
    variables: { categoryId: categoryId, currentPostId: [idResponse && idResponse] },
    skip: !categoryId,
  });
  const relatedPosts = dataRelated?.posts?.nodes || [];
  if (loading)
    return (
      <div className="load">
        <span className="loader"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;
  if (dataPage) {
    return (
      <div className="detail-blog">
        <img src={RoundMiddle} alt="RoundMiddle" className="round-middle" />
        <img src={RoundBottom} alt="RoundBottom" className="round-bottom" />
        <img
          src={HouseBottomLeft}
          alt="HouseBottomLeft"
          className="house-bottom-left"
        />
        <img
          src={HouseBottomRight}
          alt="HouseBottomRight"
          className="house-bottom-right"
        />
        <img src={HouseDetail} alt="HouseDetail" className="house-detail-img" />
        <div className="content-detail-blog max-width">
          <div className="back">
            <img onClick={handleBack} src={ArrowBack} alt="back" />
          </div>
          <h2 className="title-detail">{dataPage.title}</h2>
          <div className="author">
            <img className="round-avt" src={RoundAvt} alt="round" />
            <div className="avt">
              <img
                src={
                  dataPage.author.node.avatar.url
                    ? dataPage.author.node.avatar.url
                    : AvtDefault
                }
                alt="avt"
              />
            </div>
            <div className="content-author">
              <h4>{dataPage.author.node.name}</h4>
              <div className="time">
                <img src={Clock} alt="clock" />
                <p className="value">{formatDate(dataPage.date)}</p>
              </div>
            </div>
          </div>
          <div className="content-detail">
            <Markup content={dataPage.content} />
          </div>
          <h2 className="related-posts">
            <span className="border-top">Các bài</span> viết liên quan
          </h2>
          {relatedPosts.length > 0 && (
            <div className="list-blog-related">
              <div
                className="wrap-blog"
                // data-aos="fade-up"
                // data-aos-anchor-placement="top-bottom"
                // data-aos-offset="100"
                // data-aos-easing="ease-in-sine"
                // data-aos-duration="300"
              >
                {relatedPosts.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="date-time">
                      <span className="hi">
                        <GetDay dateString={item.date} />
                      </span>
                      <span className="tsuki">
                        <GetMonth dateString={item.date} />
                      </span>
                    </div>
                    <div className="image_feat-blog">
                      <Link to={`/blog/${item.slug}`}>
                        <img
                          src={
                            item.featuredImage
                              ? item.featuredImage.node.mediaItemUrl
                              : DefaultBlog
                          }
                          alt="Banner"
                          width="1440"
                          loading="lazy"
                          height="600"
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "block",
                          }}
                        />
                      </Link>
                    </div>
                    <div className="blog-info">
                      <div className="wrap-info">
                        <h3 className="title black">
                          <Link className="t-link" to={`/blog/${item.slug}`}>
                            {item.title}
                          </Link>
                        </h3>
                        <div className="read-time">
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.99301 1.83398C4.31301 1.83398 1.33301 4.82065 1.33301 8.50065C1.33301 12.1807 4.31301 15.1673 7.99301 15.1673C11.6797 15.1673 14.6663 12.1807 14.6663 8.50065C14.6663 4.82065 11.6797 1.83398 7.99301 1.83398ZM10.6663 11.1673C10.6047 11.2291 10.5314 11.2782 10.4508 11.3116C10.3701 11.3451 10.2837 11.3623 10.1963 11.3623C10.109 11.3623 10.0226 11.3451 9.94192 11.3116C9.86128 11.2782 9.78802 11.2291 9.72634 11.1673L7.53301 8.97398C7.47002 8.91224 7.4199 8.83861 7.38557 8.75736C7.35124 8.67611 7.33337 8.58886 7.33301 8.50065V5.83398C7.33301 5.46732 7.63301 5.16732 7.99967 5.16732C8.36634 5.16732 8.66634 5.46732 8.66634 5.83398V8.22732L10.6663 10.2273C10.9263 10.4873 10.9263 10.9073 10.6663 11.1673Z"
                              fill="#888888"
                            />
                          </svg>
                          <span className="txt">8 min read</span>
                        </div>
                        <div className="author">
                          <img
                            loading="lazy"
                            src={item.author.node.avatar.url}
                            alt="Banner"
                            width="16"
                            height="16"
                          />
                          <span className="name">{item.author.node.name}</span>
                        </div>
                        <Link to={`/blog/${item.slug}`} className="btn-read_more">
                          Read More
                          <span className="icon">
                            <svg
                              width="9"
                              height="8"
                              viewBox="0 0 9 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.47592 0.628906L7.87046 3.69342L4.47592 6.75794M7.399 3.69342H0.515625"
                                stroke="white"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        
      </div>
    );
  }
};
export default DetailBlog;
