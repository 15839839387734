import { gql } from "@apollo/client";

const BLOG = gql`
  query Blog ($language: LanguageCodeFilterEnum) {
    posts(first: 1000, where: {language: $language}) {
      nodes {
        id
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        title
        slug
        author {
          node {
            avatar {
              url
            }
            name
          }
        }
        dateGmt
      }
    }
  }
`;

export default BLOG;
