import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Import Swiper modules
import { Navigation, Thumbs } from "swiper/modules"; // Adjust the import path based on the Swiper version

const SwiperGallery = ({ arrayImage }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [height, setHeight] = useState("auto");

  const adjustSliderHeight = () => {
    const width = window.innerWidth;

    if (width >= 1440) {
      setHeight("648px");
    } else if (width <= 375) {
      setHeight("220px");
    } else {
      const minWidth = 375;
      const maxWidth = 1440;
      const minHeight = 220;
      const maxHeight = 648;

      const calculatedHeight =
        minHeight +
        (maxHeight - minHeight) * ((width - minWidth) / (maxWidth - minWidth));
      setHeight(`${calculatedHeight}px`);
    }
  };
  useEffect(() => {
    adjustSliderHeight();
    window.addEventListener("resize", adjustSliderHeight);
    return () => {
      window.removeEventListener("resize", adjustSliderHeight);
    };
  }, []);

  return (
    <div
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-offset="100"
      data-aos-easing="ease-in-sine"
      data-aos-duration="300"
      className="slider slide-detail"
    >
      <div className="large-image" style={{ height }}>
        <Swiper
          spaceBetween={5}
          navigation={{
            nextEl: ".l-btn-next",
            prevEl: ".l-btn-prev",
          }}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[Navigation, Thumbs]}
          className="large-image-swiper"
        >
          {arrayImage.map((item, index) => (
            <SwiperSlide key={index}>
              <img
                src={item}
                loading="lazy"
                alt="img"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-prev large-img l-btn-prev"></div>
        <div className="swiper-button-next large-img l-btn-next"></div>
      </div>

      <div className="thumbnail-image" style={{ height }}>
        <Swiper
          onSwiper={setThumbsSwiper}
          direction="vertical"
          spaceBetween={8}
          slidesPerView={4}
          freeMode
          watchSlidesProgress
          slidesPerGroup={4}
          navigation={{
            nextEl: ".s-btn-next",
            prevEl: ".s-btn-prev",
          }}
          breakpoints={{
            768: { spaceBetween: 16 },
          }}
          modules={[Navigation, Thumbs]}
          className="thumbnail-image-swiper"
        >
          {arrayImage.map((item, index) => (
            <SwiperSlide key={index}>
              <img
                src={item}
                loading="lazy"
                alt="img"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-prev thumb-img s-btn-prev"></div>
        <div className="swiper-button-next thumb-img s-btn-next"></div>
      </div>
    </div>
  );
};

export default SwiperGallery;
