import { gql } from "@apollo/client";

const GET_DETAIL_HOUSE = gql`
  query GET_DETAIL_HOUSE($idHouse: String, $input: ID!, $idPage: ID!) {
    wishlistHouses(userID: $input) {
      id
      title
    }
    getHouseByCode(houseCode: $idHouse) {
      houseId
      acreage
      address
      ceremonyMoney
      deposit
      design
      featuredImage
      houseCode
      latitude
      listAlbum
      longitude
      managementFee
      price
      roomCode
      title
      trafficContent
    }
    page(id: $idPage, idType: URI) {
      detailHouse {
        contentDataDetailHouse {
          labelInformation {
            dataValue
          }
          linkMessage {
            target
            title
            url
          }
          linkPhone {
            target
            title
            url
          }
        }
      }
    }
  }
`;

export default GET_DETAIL_HOUSE;
