import { gql } from "@apollo/client";

const GET_DETAIL_BLOG = gql`
  query GET_DETAIL_BLOG($idBlog: String) {
    postBy(slug: $idBlog) {
      id
      title
      slug
      content
      date
      dateGmt
      categories {
        nodes {
          id
          name
        }
      }
      translations {
        id
        title
        slug
        content
        language {
          code
        }
        date
        dateGmt
        categories {
          nodes {
            id
            name
          }
        }
        author {
          node {
            avatar {
              url
            }
            name
          }
        }
      }
      author {
        node {
          avatar {
            url
          }
          name
        }
      }
    }
  }
`;

export default GET_DETAIL_BLOG;
