import { gql } from "@apollo/client";

const WISHLIST_HOUSE = gql`
  query WishlistHouse($input: ID!, $idPage: ID!) {
    page(id: $idPage, idType: URI) {
      wishlistPage {
        contentPage {
          notFoundLabel
          labelContent {
            dataLabel
          }
        }
      }
    }
    wishlistHouses(userID: $input) {
      id
      title
      address
      ceremony_money
      deposit
      management_fee
      price
      slug
      traffic
      houseCode
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }
`;
export default WISHLIST_HOUSE;
