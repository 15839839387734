import React, { useEffect, useRef, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import BgFind from "../assets/img/form-filter-bgr.png";
import MapFluent from "../assets/img/map_fluent.svg";
import MapActive from "../assets/img/map_active.svg";
import ListFluent from "../assets/img/list_fluent.svg";
import ListActive from "../assets/img/list_active.svg";
import { useNavigate } from "react-router-dom";
import {
  getDataSearch,
  getSearchHistory,
  getStatusLogin,
  setDataSearch,
  setSearchHistory,
} from "../ulti/function";
import FORMSEARCH from "../graphql/form-search";
import { useQuery } from "@apollo/client";
import { useLanguage } from "./LanguageProvider";
import { geocode, RequestType, setDefaults } from "react-geocode";

const loadGoogleMapsApi = (apiKey) => {
  return new Promise((resolve, reject) => {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

const FormFind = ({ bgColor, checkHome, dataGlobal }) => {
  const [dataSettingForm, setSettingDataForm] = useState();
  const dataSearchLocal = getDataSearch();
  const [view, setView] = useState("map");
  const [price, setPrice] = useState(
    dataSearchLocal
      ? dataSearchLocal.price
      : dataSettingForm
      ? dataSettingForm.max
      : 500000
  );
  const [designs, setDesigns] = useState(
    dataSearchLocal ? dataSearchLocal.design : []
  );
  const [inputValue, setInputValue] = useState(
    dataSearchLocal ? dataSearchLocal.inputValue : ""
  );
  const [inputValueLocation, setInputValueLocation] = useState("");
  const [inputValueLocationHistory, setInputValueLocationHistory] =
    useState("");
  const autocompleteRef = useRef(null);
  const [dataForm, setDataForm] = useState();
  const inputRef = useRef(null);
  const [searchHistory, setSearchHistoryState] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const initAutocomplete = async () => {
      try {
        const key = dataGlobal
          ? dataGlobal.messageFormACF.contentMessageForm.ggMapKey
          : "AIzaSyCOJTATNUYyc0o5sCsPLwxjwmGuhRJLE5Y";
        await loadGoogleMapsApi(key);

        autocompleteRef.current = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            types: ["geocode"],
          }
        );

        autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
      } catch (error) {
        console.error("Google Maps API failed to load:", error);
      }
    };

    initAutocomplete();
    setSearchHistoryState(getSearchHistory());
  }, []);
  const clearMoney = () => {
    setPrice(0);
  };
  const { activeLanguage } = useLanguage();
  const { loading, error, refetch } = useQuery(FORMSEARCH, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setSettingDataForm(
        data.globalWidget.globalWidgetContent.formSearch.settingForm
      );
      const content =
        data.globalWidget.globalWidgetContent.formSearch.globalWidgetField.find(
          (item) => item.language.includes(activeLanguage)
        );
      setDataForm(content);
    },
    onError: (error) => {
      console.log("Query error:", error);
    },
  });
  useEffect(() => {
    refetch()
      .then((result) => {
        const content =
          result.data.globalWidget.globalWidgetContent.formSearch.globalWidgetField.find(
            (item) => item.language.includes(activeLanguage)
          );
        setDataForm(content);
      })
      .catch((error) => {
        console.log("Error during refetch:", error);
      });
  }, [refetch, activeLanguage]);
  const handleViewChange = (event) => {
    setView(event.target.value);
  };

  const handleDesignChange = (event) => {
    const value = event.target.value;
    setDesigns((prevDesigns) =>
      prevDesigns.includes(value)
        ? prevDesigns.filter((design) => design !== value)
        : [...prevDesigns, value]
    );
  };
  setDefaults({
    key: "AIzaSyCOJTATNUYyc0o5sCsPLwxjwmGuhRJLE5Y", // Your API key here.
    language: "en", // Default language for responses.
    region: "es", // Default region for responses.
  });

  const statusLogin = getStatusLogin();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!statusLogin) {
      navigate("/login");
    } else {
      var lat = 0;
      var lng = 0;
      const dataSearch = {
        design: designs,
        price: price,
        inputValue: inputValue,
      };
      setDataSearch(dataSearch);
      var currentSearch = "";
      if (inputValueLocationHistory !== "") {
        currentSearch = inputValueLocationHistory.trim();
      } else {
        currentSearch = inputValue.trim();
      }
      if (currentSearch) {
        if (searchHistory && searchHistory.length >= 5) {
          searchHistory.pop();
        }
        searchHistory.unshift(currentSearch);
        setSearchHistory(searchHistory);
        if (view === "map") {
          if (inputValueLocation !== "") {
            lat = inputValueLocation.lat();
            lng = inputValueLocation.lng();
          } else {
            try {
              const response = await geocode(RequestType.ADDRESS, inputValue);
              lat = response.results[0].geometry.location.lat;
              lng = response.results[0].geometry.location.lng;
            } catch (error) {
              console.error(error);
              return;
            }
          }
          navigate("/map", {
            state: { lat, lng, price, inputValue, designs },
          });
        } else {
          navigate("/houses", {
            state: {price, inputValue, designs },
          });
        }
      }
    }
  };

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    setInputValueLocationHistory(place.name);
    setInputValueLocation(place.geometry.location);
    setInputValue(place.formatted_address || place.name);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.relatedTarget)) {
      setIsFocused(false);
    }
  };
  useEffect(() => {
    const inputElement = inputRef.current;
    inputElement.addEventListener("focus", handleFocus);
    inputElement.addEventListener("blur", handleBlur);
    return () => {
      inputElement.removeEventListener("focus", handleFocus);
      inputElement.removeEventListener("blur", handleBlur);
    };
  }, []);
  const handleChangeLocation = async (index) => {
    setInputValue(searchHistory[index]);
    var lat = 0;
    var lng = 0;
    if (inputValueLocation !== "") {
      lat = inputValueLocation.lat();
      lng = inputValueLocation.lng();
    } else {
      try {
        const response = await geocode(RequestType.ADDRESS, inputValue);
        lat = response.results[0].geometry.location.lat;
        lng = response.results[0].geometry.location.lng;
      } catch (error) {
        console.error(error);
        return;
      }
    }
    if (!statusLogin) {
      navigate("/login");
    } else {
      if (view === "map") {
        navigate("/map", {
          state: { lat, lng, price, inputValue, designs },
        });
      } else {
        navigate("/houses", {
          state: { price, inputValue, designs },
        });
      }
    }
  };
  const handleClearText = () => {
    setInputValue("");
  };
  return (
    <div
      className={`section find-location ${!checkHome ? "not-home" : ""}`}
      style={{ backgroundColor: bgColor }}
    >
      <div
        className={`wrap-box form-container ${!checkHome ? "not-home" : ""}`}
        style={{ backgroundImage: `url(${BgFind})` }}
      >
        <form onSubmit={handleSubmit}>
          <div
            className={`form-group input-loca ${!checkHome ? "not-home" : ""}`}
          >
            <div className="text-input-submit">
              <input
                type="text"
                id="location"
                name="location"
                placeholder={
                  dataForm && dataForm.formSearchField.textPlaceholderInput
                }
                className={`pac-target-input ${
                  isFocused ? "forcus-input" : ""
                }`}
                required
                value={inputValue}
                onChange={handleChange}
                ref={inputRef}
              />
              {inputValue.length > 0 && (
                <span onClick={handleClearText} className="x-button">
                  X
                </span>
              )}
            </div>
            {!checkHome && (
              <button type="submit">
                <span>
                  {dataForm && dataForm.formSearchField.textButtonSubmit}
                </span>
              </button>
            )}
            <div
              className="history-search"
              style={{ display: !checkHome && "none" }}
            >
              {checkHome && searchHistory && searchHistory.length > 0 && (
                <div className="wrap">
                  {searchHistory.map((item, index) => (
                    <span
                      key={index}
                      onClick={() => handleChangeLocation(index)}
                    >
                      {item}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="form-group find-type">
            <label className={`group lb-map ${view === "map" ? "active" : ""}`}>
              <input
                type="radio"
                id="map"
                name="view"
                value="map"
                checked={view === "map"}
                onChange={handleViewChange}
              />
              <span
                className="icon"
                style={{
                  backgroundImage: `url(${
                    view === "map" ? MapActive : MapFluent
                  })`,
                }}
              ></span>
              <span className="lb mp">
                {dataForm && dataForm.formSearchField.textMap}
              </span>
            </label>
            <label
              className={`group lb-list ${view === "list" ? "active" : ""}`}
            >
              <input
                type="radio"
                id="list"
                name="view"
                value="list"
                checked={view === "list"}
                onChange={handleViewChange}
              />
              <span
                className="icon"
                style={{
                  backgroundImage: `url(${
                    view === "list" ? ListActive : ListFluent
                  })`,
                }}
              ></span>
              <span className="lb lt">
                {dataForm && dataForm.formSearchField.textList}
              </span>
            </label>
          </div>
          <div className="form-group cate-filter">
            <div className="filter yachin">
              <div className="label-form">
                <label className="heading">
                  {dataForm && dataForm.formSearchField.textHousePrice}
                </label>
                {price > 0 && <span onClick={clearMoney}>Clear</span>}
              </div>
              <div className="kakaku">
                <div className="slider-container line-ka">
                  <Slider
                    step={dataSettingForm ? dataSettingForm.step : 5000}
                    min={dataSettingForm ? dataSettingForm.min : 0}
                    max={dataSettingForm ? dataSettingForm.max : 5000000}
                    value={price}
                    onChange={(value) => setPrice(value)}
                  />
                </div>
                <div className="okane">
                  <span className="kazu">
                    {Intl.NumberFormat("en-US").format(price) + " "}
                  </span>
                  <span className="tani">
                    {dataForm && dataForm.formSearchField.textForeignCurrencies}
                  </span>
                </div>
              </div>
            </div>
            <div className="filter dezain">
              <label className="heading">
                {dataForm && dataForm.formSearchField.textDesign}
              </label>
              <div className="checkbox-group">
                {dataForm &&
                  dataForm.formSearchField.labelInputCheckbox
                    .reduce((acc, item, index) => {
                      const groupIndex = Math.floor(index / 3);
                      if (!acc[groupIndex]) {
                        acc[groupIndex] = [];
                      }
                      acc[groupIndex].push(item);

                      return acc;
                    }, [])
                    .map((group, groupIndex) => (
                      <div key={groupIndex} className={`col-${groupIndex + 1}`}>
                        {group.map((item, itemIndex) => (
                          <label key={itemIndex}>
                            <input
                              key={itemIndex}
                              type="checkbox"
                              name={`design${itemIndex + 1}`}
                              value={`${item.value}`}
                              onChange={handleDesignChange}
                              checked={designs && designs.includes(item.value)}
                            />
                            <span className="checkmark">
                              <svg
                                width="15"
                                height="13"
                                viewBox="0 0 15 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 7.5L5.5 11L13.5 2"
                                  stroke="white"
                                  strokeWidth="3"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            {item.value}
                          </label>
                        ))}
                      </div>
                    ))}
              </div>
            </div>
          </div>
          <div className="form-group wrap-btn-submit">
            <button type="submit">
              <span>
                {dataForm && dataForm.formSearchField.textButtonSubmit}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormFind;
