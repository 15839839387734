import React, { useEffect, useState } from "react";
import BLOG from "../graphql/blog";
import { useQuery } from "@apollo/client";
import "../assets/style/blog.css";
import { GetDay, GetMonth } from "../global/FormatDate";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import LeftIcon from "../assets/img/left.svg";
import RightIcon from "../assets/img/right.svg";
import FirstIcon from "../assets/img/First.svg";
import LastIcon from "../assets/img/Last.svg";
import { getStatusLogin } from "../ulti/function";
import DefaultBlog from "../assets/img/default_blog.png";
import { useLanguage } from "../global/LanguageProvider";
import Aos from "aos";

const Blog = ({ itemsPerPage }) => {
  useEffect(() => {
    Aos.init();
  }, []);
  const navigate = useNavigate();
  const statusLogin = getStatusLogin();
  useEffect(() => {
    if (!statusLogin) {
      navigate("/login");
    }
  }, []);
  const { activeLanguage } = useLanguage();
  const { loading, error, data } = useQuery(BLOG, {
    variables: {
      language: activeLanguage.toUpperCase(),
    },
  });
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  if (loading)
    return (
      <div className="load">
        <span className="loader"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;
  if (data) {
    const filteredData = data.posts.nodes.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredData.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredData.length / itemsPerPage);
    // Invoke when user clicks to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % filteredData.length;
      setItemOffset(newOffset);
      setSelectedPage(event.selected);
    };

    const goToFirstPage = () => {
      setSelectedPage(0);
      setItemOffset(0);
    };

    const goToLastPage = () => {
      setSelectedPage(pageCount - 1);
      setItemOffset((pageCount - 1) * itemsPerPage);
    };
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
      setItemOffset(0);
      setSelectedPage(0);
    };
    const handleBack = () => {
      navigate(-1);
    };
    return (
      <div className="wrap-page bgr-right blog-list">
        <div className="box-width section-top row-search">
          <div className="btn-back" onClick={handleBack}>
            <svg
              width="28"
              height="24"
              viewBox="0 0 28 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.712 10.2869H5.38237L11.6047 2.81332C11.8956 2.46327 12.0356 2.01197 11.9938 1.55871C11.952 1.10545 11.7319 0.687351 11.3818 0.396397C11.0318 0.105443 10.5805 -0.0345367 10.1272 0.00725335C9.67396 0.0490434 9.25587 0.26918 8.96491 0.619234L0.39425 10.904C0.336588 10.9858 0.285025 11.0718 0.239978 11.1611C0.239978 11.2469 0.239979 11.2983 0.11999 11.384C0.0422939 11.5805 0.00161321 11.7897 0 12.0011C0.00161321 12.2124 0.0422939 12.4216 0.11999 12.6182C0.11999 12.7039 0.119989 12.7553 0.239978 12.841C0.285025 12.9304 0.336588 13.0163 0.39425 13.0981L8.96491 23.3829C9.12608 23.5764 9.3279 23.732 9.55602 23.8387C9.78415 23.9453 10.033 24.0004 10.2848 24C10.6853 24.0008 11.0734 23.8613 11.3818 23.6057C11.5554 23.4618 11.6989 23.2851 11.804 23.0857C11.9092 22.8862 11.974 22.668 11.9947 22.4435C12.0154 22.219 11.9916 21.9926 11.9247 21.7773C11.8578 21.562 11.749 21.362 11.6047 21.1888L5.38237 13.7152H25.712C26.1666 13.7152 26.6026 13.5346 26.9241 13.2131C27.2455 12.8917 27.4261 12.4557 27.4261 12.0011C27.4261 11.5465 27.2455 11.1105 26.9241 10.789C26.6026 10.4675 26.1666 10.2869 25.712 10.2869Z"
                fill="#212121"
              />
            </svg>
          </div>
          <form id="searchForm">
            <input
              type="text"
              onChange={handleSearchChange}
              id="searchInput"
              placeholder="Seach"
            />
          </form>
        </div>
        <div className="list-blog bgr-left">
          <div className="container box-width wrap-blog">
            {currentItems.map((item, index) => (
              <div
                className="item"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-offset="100"
                data-aos-easing="ease-in-sine"
                data-aos-duration="300"
                key={index}
              >
                <div className="date-time">
                  <span className="hi">
                    <GetDay dateString={item.dateGmt} />
                  </span>
                  <span className="tsuki">
                    <GetMonth dateString={item.dateGmt} />
                  </span>
                </div>
                <div className="image_feat-blog">
                  <Link to={`/blog/${item.slug}`}>
                    <img
                      src={
                        item.featuredImage
                          ? item.featuredImage.node.mediaItemUrl
                          : DefaultBlog
                      }
                      alt="Banner"
                      width="1440"
                      loading="lazy"
                      height="600"
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "block",
                      }}
                    />
                  </Link>
                </div>
                <div className="blog-info">
                  <div className="wrap-info">
                    <h3 className="title black">
                      <Link className="t-link" to={item.slug}>
                        {item.title}
                      </Link>
                    </h3>
                    <div className="read-time">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.99301 1.83398C4.31301 1.83398 1.33301 4.82065 1.33301 8.50065C1.33301 12.1807 4.31301 15.1673 7.99301 15.1673C11.6797 15.1673 14.6663 12.1807 14.6663 8.50065C14.6663 4.82065 11.6797 1.83398 7.99301 1.83398ZM10.6663 11.1673C10.6047 11.2291 10.5314 11.2782 10.4508 11.3116C10.3701 11.3451 10.2837 11.3623 10.1963 11.3623C10.109 11.3623 10.0226 11.3451 9.94192 11.3116C9.86128 11.2782 9.78802 11.2291 9.72634 11.1673L7.53301 8.97398C7.47002 8.91224 7.4199 8.83861 7.38557 8.75736C7.35124 8.67611 7.33337 8.58886 7.33301 8.50065V5.83398C7.33301 5.46732 7.63301 5.16732 7.99967 5.16732C8.36634 5.16732 8.66634 5.46732 8.66634 5.83398V8.22732L10.6663 10.2273C10.9263 10.4873 10.9263 10.9073 10.6663 11.1673Z"
                          fill="#888888"
                        />
                      </svg>
                      <span className="txt">8 min read</span>
                    </div>
                    <div className="author">
                      <img
                        loading="lazy"
                        src={item.author.node.avatar.url}
                        alt="Banner"
                        width="16"
                        height="16"
                      />
                      <span className="name">{item.author.node.name}</span>
                    </div>
                    <Link to={`/blog/${item.slug}`} className="btn-read_more">
                      Read More
                      <span className="icon">
                        <svg
                          width="9"
                          height="8"
                          viewBox="0 0 9 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.47592 0.628906L7.87046 3.69342L4.47592 6.75794M7.399 3.69342H0.515625"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {pageCount > 1 && (
          <div className="pagination-buttons">
            <button onClick={goToFirstPage}>
              <img loading="lazy" src={FirstIcon} alt="icon" />
            </button>
            <ReactPaginate
              breakLabel="..."
              nextLabel={<img loading="lazy" src={RightIcon} alt="Next page" />}
              onPageChange={handlePageClick}
              forcePage={selectedPage} // Ensure the selected page is active
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel={
                <img loading="lazy" src={LeftIcon} alt="Previous page" />
              }
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
            <button onClick={goToLastPage}>
              <img loading="lazy" src={LastIcon} alt="icon" />
            </button>
          </div>
        )}
      </div>
    );
  }
};
export default Blog;
