import { gql } from "@apollo/client";

const GET_RELATED_POSTS = gql`
  query GET_RELATED_POSTS($categoryId: [ID], $currentPostId: [ID]) {
    posts(
      where: { categoryIn: $categoryId, notIn: $currentPostId}
      first: 3
    ) {
      nodes {
        id
        title
        slug
        date
        author {
          node {
            name
            avatar {
              url
            }
          }
        }
      }
    }
  }
`;
export default GET_RELATED_POSTS;
