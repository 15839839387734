import React, { useEffect, useState } from "react";
import GET_DETAIL_HOUSE from "../graphql/detail-house";
import { useMutation, useQuery } from "@apollo/client";
import IconPrice from "../assets/img/money.svg";
import IconAddress from "../assets/img/gis_poi-map.svg";
import IconTraffic from "../assets/img/mingcute_traffic-lights-fill.svg";
import IconManagementFee from "../assets/img/fa-solid_money-check-alt.svg";
import Deposit from "../assets/img/icon-park-outline_deposit.svg";
import CeremonyMoney from "../assets/img/ph_hand-coins-fill.svg";
import Acreage from "../assets/img/carbon_area.svg";
import HouseCode from "../assets/img/ph_code-fill.svg";
import Phone from "../assets/img/bxs_phone-call.svg";
import "../assets/style/detail-house.css";
import "../assets/style/swiper-thumb.css";
import SwiperGallery from "../global/SwiperGallery";
import ToastCustom from "../global/ToastCustom";
import { useLanguage } from "../global/LanguageProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getInforUser,
  getStatusLogin,
  NumberFormatter,
} from "../ulti/function";
import HeartDefault from "../assets/img/heart_default.svg";
import HeartActive from "../assets/img/heart_active.svg";
import ADD_WISHLIST from "../graphql/add-wishlist";
import REMOVE_WISHLIST from "../graphql/remove-wishlist";
import Img1 from "../assets/img/9e3f0753f911bdbd2ace220a20cff730.png";
import Img2 from "../assets/img/500f39df3e44cd345f76d5649bcc2a4d.png";
import Img3 from "../assets/img/ac578b86806eb6c25c4f5267ede0b0ca.png";
import Img4 from "../assets/img/dc5753762fadb539777a454963e7e826.png";
import Aos from "aos";

const DetailHouse = ({ uri }) => {
  const [toastMessage, setToastMessage] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [redirectLink, setRedirectLink] = useState("");
  useEffect(() => {
    Aos.init();
  }, []);
  const arrayDefault = [Img1, Img2, Img3, Img4, Img1];
  const location = useLocation();
  const param = location.pathname.split('/house/')[1]; 
  const copyToClipboard = () => {
    setToastMessage("");
    setToastStatus("");
    const textToCopy = document.querySelector(".house-code").textContent;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setToastMessage("Success!!!");
        setToastStatus("success");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  const statusLogin = getStatusLogin();
  const navigate = useNavigate();
  useEffect(() => {
    if (!statusLogin) {
      navigate("/login");
    }
  }, []);
  const { activeLanguage } = useLanguage();
  const [wishlist, setWishlist] = useState([]);
  const [dataPage, setDataPage] = useState();
  const [dataHouseInfor, setDataHouseInfor] = useState();
  const handleBack = () => {
    navigate(-1);
  };
  const inforUser = getInforUser();
  const { loading, error, refetch } = useQuery(GET_DETAIL_HOUSE, {
    variables: {
      idHouse: param,
      input: inforUser && inforUser.userId,
      idPage: uri,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data.getHouseByCode === null) {
        navigate("/404");
      }
      setWishlist(data.wishlistHouses);
      setDataHouseInfor(data.page.detailHouse.contentDataDetailHouse);
      setDataPage(data);
    },
    onError: (error) => {
      navigate("/404");
      console.log("Query error:", error);
    },
  });
  const [addWishlist, { loading: wishlistLoading, error: wishlistError }] =
    useMutation(ADD_WISHLIST, {
      onCompleted: async (data) => {
        try {
          // await refetch();
        } catch (refetchError) {
          console.log("Error during refetch:", refetchError);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });
  const [
    removeWishlist,
    { loading: removeWishlistLoading, error: removeWishlistError },
  ] = useMutation(REMOVE_WISHLIST, {
    onCompleted: async (data) => {
      // await refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleWishlistToggle = async (id, isRemoving = false) => {
    const buttonElement = document.querySelector(`[data-id="${id}"]`);
    if (isRemoving) {
      if (buttonElement.classList.contains("active-heart")) {
        buttonElement.classList.remove("active-heart");
      }
      try {
        await removeWishlist({
          variables: {
            input: {
              userId: inforUser && inforUser.userId,
              houseId: parseInt(id),
            },
          },
        });
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      if (buttonElement.classList.contains("active-heart")) {
        buttonElement.classList.remove("active-heart");
      } else {
        buttonElement.classList.add("active-heart");
      }
      try {
        await addWishlist({
          variables: {
            input: {
              userId: inforUser && inforUser.userId,
              houseId: parseInt(id),
            },
          },
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  // useEffect(() => {
  //   refetch()
  //     .then((result) => {
  //       console.log(result);

  //       setDataHouseInfor(result.data.page.detailHouse.contentDataDetailHouse);
  //       setDataPage(result.data);
  //       setWishlist(result.data.wishlistHouses);
  //     })
  //     .catch((error) => {
  //       console.log("Error during refetch:", error);
  //     });
  // }, [refetch]);
  if (loading)
    return (
      <div className="load">
        <span className="loader"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;
  if (dataPage) {
    return (
      <div className="detail-house wrap-page bgr-right">
        <div className="bgr-img bgr-left"></div>
        {toastMessage !== "" && toastStatus !== "" && (
          <ToastCustom
            message={toastMessage}
            status={toastStatus}
            redirect={redirectLink}
            speed={redirectLink ? 500 : 2000}
          />
        )}
        <div className="container-detail">
          <div className="telephone">
            <Link to={dataHouseInfor.linkPhone.url}>
              <img loading="lazy" src={Phone} alt="phone" />
            </Link>
            <div className="ripple"></div>
          </div>
          <div className="box-width section-top row-back">
            <div className="btn-back" onClick={handleBack}>
              <svg
                width="28"
                height="24"
                viewBox="0 0 28 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.712 10.2869H5.38237L11.6047 2.81332C11.8956 2.46327 12.0356 2.01197 11.9938 1.55871C11.952 1.10545 11.7319 0.687351 11.3818 0.396397C11.0318 0.105443 10.5805 -0.0345367 10.1272 0.00725335C9.67396 0.0490434 9.25587 0.26918 8.96491 0.619234L0.39425 10.904C0.336588 10.9858 0.285025 11.0718 0.239978 11.1611C0.239978 11.2469 0.239979 11.2983 0.11999 11.384C0.0422939 11.5805 0.00161321 11.7897 0 12.0011C0.00161321 12.2124 0.0422939 12.4216 0.11999 12.6182C0.11999 12.7039 0.119989 12.7553 0.239978 12.841C0.285025 12.9304 0.336588 13.0163 0.39425 13.0981L8.96491 23.3829C9.12608 23.5764 9.3279 23.732 9.55602 23.8387C9.78415 23.9453 10.033 24.0004 10.2848 24C10.6853 24.0008 11.0734 23.8613 11.3818 23.6057C11.5554 23.4618 11.6989 23.2851 11.804 23.0857C11.9092 22.8862 11.974 22.668 11.9947 22.4435C12.0154 22.219 11.9916 21.9926 11.9247 21.7773C11.8578 21.562 11.749 21.362 11.6047 21.1888L5.38237 13.7152H25.712C26.1666 13.7152 26.6026 13.5346 26.9241 13.2131C27.2455 12.8917 27.4261 12.4557 27.4261 12.0011C27.4261 11.5465 27.2455 11.1105 26.9241 10.789C26.6026 10.4675 26.1666 10.2869 25.712 10.2869Z"
                  fill="#212121"
                />
              </svg>
            </div>
            <div
              data-id={dataPage.getHouseByCode.houseId}
              onClick={() => {
                const isRemoving = wishlist.some(
                  (item) => item.id === String(dataPage.getHouseByCode.houseId)
                );
                handleWishlistToggle(
                  dataPage.getHouseByCode.houseId,
                  isRemoving
                );
              }}
              className={`btn-filter ${
                wishlist.some(
                  (item) => item.id === String(dataPage.getHouseByCode.houseId)
                )
                  ? "active-heart"
                  : ""
              }`}
            >
              {wishlistLoading || removeWishlistLoading ? (
                <span className="loader load-ajax"></span>
              ) : (
                <>
                  <img
                    loading="lazy"
                    className="heart"
                    width={"33px"}
                    src={HeartDefault}
                    alt="heart"
                  />
                  <img
                    loading="lazy"
                    className="heart heart-red"
                    width={"36px"}
                    src={HeartActive}
                    alt="heart"
                  />
                </>
              )}
            </div>
          </div>
          <h2 className="title-detail box-width">
            {dataPage.getHouseByCode.houseCode &&
              dataPage.getHouseByCode.houseCode}
          </h2>
          <SwiperGallery
            arrayImage={
              dataPage.getHouseByCode.listAlbum
                ? dataPage.getHouseByCode.listAlbum
                : arrayDefault
            }
          />
          <h2 className="title-detail-mb box-width">
            {dataPage.getHouseByCode.title}
          </h2>
          <div
            className="content-detail"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine"
            data-aos-duration="300"
          >
            <div className="field-content field-price">
              <div className="title-icon">
                <img
                  loading="lazy"
                  width={"24px"}
                  height={"24px"}
                  src={IconPrice}
                  alt="icon_price"
                />
                <span>{dataHouseInfor.labelInformation[0].dataValue}</span>
              </div>
              <div className="value">
                {dataPage.getHouseByCode.price && (
                  <NumberFormatter
                    numberString={dataPage.getHouseByCode.price}
                  />
                )}
              </div>
            </div>
            <div className="field-content field-address">
              <div className="title-icon">
                <img
                  loading="lazy"
                  width={"24px"}
                  height={"24px"}
                  src={IconAddress}
                  alt="icon_address"
                />
                <span>{dataHouseInfor.labelInformation[1].dataValue}</span>
              </div>
              <div className="value">
                {dataPage.getHouseByCode.address &&
                  dataPage.getHouseByCode.address}
              </div>
            </div>
            <div className="field-content field-traffic">
              <div className="title-icon">
                <img
                  loading="lazy"
                  width={"24px"}
                  height={"24px"}
                  src={IconTraffic}
                  alt="icon_traffic"
                />
                <span>{dataHouseInfor.labelInformation[2].dataValue}</span>
              </div>
              <div className="value">
                {dataPage.getHouseByCode.trafficContent &&
                  dataPage.getHouseByCode.trafficContent.map((item, index) => (
                    <p key={index}>{item}</p>
                  ))}
              </div>
            </div>
            <div className="field-content field-management">
              <div className="title-icon">
                <img
                  loading="lazy"
                  width={"24px"}
                  height={"24px"}
                  src={IconManagementFee}
                  alt="icon_management"
                />
                <span>{dataHouseInfor.labelInformation[3].dataValue}</span>
              </div>
              <div className="value">
                {dataPage.getHouseByCode.managementFee
                  ? dataPage.getHouseByCode.managementFee
                  : 0}
              </div>
            </div>
            <div className="field-content field-deposit">
              <div className="title-icon">
                <img
                  loading="lazy"
                  width={"24px"}
                  height={"24px"}
                  src={Deposit}
                  alt="icon_deposit"
                />
                <span>{dataHouseInfor.labelInformation[4].dataValue}</span>
              </div>
              <div className="value">{dataPage.getHouseByCode.deposit}</div>
            </div>
            <div className="field-content field-ceremonyMoney">
              <div className="title-icon">
                <img
                  loading="lazy"
                  width={"24px"}
                  height={"24px"}
                  src={CeremonyMoney}
                  alt="icon_ceremonyMoney"
                />
                <span>{dataHouseInfor.labelInformation[5].dataValue}</span>
              </div>
              <div className="value">
                {dataPage.getHouseByCode.ceremonyMoney}
              </div>
            </div>
            <div className="field-content field-acreage">
              <div className="title-icon">
                <img
                  loading="lazy"
                  width={"24px"}
                  height={"24px"}
                  src={Acreage}
                  alt="icon_acreage"
                />
                <span>{dataHouseInfor.labelInformation[6].dataValue}</span>
              </div>
              <div className="value">{dataPage.getHouseByCode.acreage}</div>
            </div>
            <div className="field-content field-housecode">
              <div className="title-icon">
                <img
                  loading="lazy"
                  width={"24px"}
                  height={"24px"}
                  src={HouseCode}
                  alt="icon_housecode"
                />
                <span>{dataHouseInfor.labelInformation[7].dataValue}</span>
              </div>
              <div className="value coppy-value" onClick={copyToClipboard}>
                <span className="house-code">
                  {dataPage.getHouseByCode.houseCode}
                </span>
                <span className="coppy" onClick={copyToClipboard}>
                  Copy
                </span>
              </div>
            </div>
          </div>
          <div className="button-action">
            {/* <button>Tư vấn ngay</button> */}
            <Link to={dataHouseInfor.linkMessage.url}>
              {dataHouseInfor.linkMessage.title}
            </Link>
          </div>
        </div>
      </div>
    );
  }
};
export default DetailHouse;
